import React from 'react';

const Header = () => {
  return (
    <div className="header-container">
      <h1 className="header-text">hi, my name is</h1>
      <h1 className="header-name">elmer gonzalez</h1>
    </div>
  );
};

export default Header;
